import { InputChangeEventDetail, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSpinner, IonText, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { AccountContext } from "../../contexts/AccountContext";
import { RowWithDynamicGutter } from "../controls/shared/Grid";
import PageSpinner from "../controls/shared/PageSpinner";
import './SubMerchant.css';
import { PageAndContent } from "../controls/nav/PageControls";
import { DeactivateSubMerchant } from "../../lib/data/MerchantData";

export default function SubMerchantList() {
    var accountContext = useContext(AccountContext);
    var [filter, setFilter] = useState("");
    const router = useIonRouter();
    const [subAccountToDelete, setSubAccountToDelete] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const lockApiCalls = useRef(false);

    useEffect(() => {
        async function refreshAccountContext() {
            lockApiCalls.current = true;

            try {
                await accountContext?.refresh();
            } finally {
                lockApiCalls.current = false;
            }
        }

        if (lockApiCalls.current || accountContext?.merchant) return;
        refreshAccountContext();
    }, [accountContext]);

    function viewMerchant(merchantName: string) {
        router.push(`/tabs/submerchants/${merchantName}`);
    }

    async function deleteSubAccount() {
        if (!subAccountToDelete) return;

        try {
            setIsDeleting(true);
            await DeactivateSubMerchant(subAccountToDelete);
            setSubAccountToDelete(null);
        } catch (e) {
            console.error("Error deleting Subaccount:", e);
        } finally {
            setIsDeleting(false);
            await accountContext?.refresh();
        }
    }

    function statusStyling(status: string) {
        switch (status) {
            case "Active":
                return "#4CAF50"; // Green
            case "Invited":
                return "#FF9800"; // Orange
            case "Deactivated":
                return "#7C0000"; // Red
            default:
                return "#9E9E9E"; // Grey for unknown status
        }
    }

    return <>{accountContext?.merchant
        ? <>
            <PageAndContent toolbarChildren={<IonText class='titleBarText'>Subaccounts</IonText>}>
                <div className="greyPanelParentContainer">
                    <IonGrid fixed style={{ padding: "10px" }} className="tabPageBottomMargin greyPanelNoPadding">
                        <RowWithDynamicGutter>
                            <IonText style={{ fontSize: '12px', fontWeight: '400', color: '#003466' }}>Search Subaccounts</IonText>
                            <IonInput
                                style={{ background: '#fff' }}
                                placeholder='Filter by Name/Location Name'
                                className="input"
                                onIonChange={(val: CustomEvent<InputChangeEventDetail>) => setFilter(val.detail.value!)}
                                value={filter}>
                            </IonInput>
                        </RowWithDynamicGutter>
                        <IonList className="greyPanelNoPadding">
                            {accountContext?.merchant.submerchants?.filter(s => filter === "" || s?.name?.toLowerCase().indexOf(filter.toLowerCase()) > -1).map((submerchant, index) => {
                                return (
                                    <IonItem className='greyItem'
                                        id={`submerchant_${submerchant.name}`}
                                        key={submerchant.name}>
                                        <IonCol className="selectableItem" onClick={() => viewMerchant(submerchant.name)} style={{ paddingRight: '20px' }}>
                                            <IonLabel className="listItemLabel">
                                                <h2 style={{ fontWeight: 'bold', color: '#003466' }}>{submerchant.name}</h2>
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol size="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', minWidth: 'fit-content' }}>
                                            <IonLabel className="listItemLabel">
                                                <h2 style={{ color: statusStyling(submerchant.status) }}>{submerchant.status}</h2>
                                            </IonLabel>
                                            <IonButton
                                                className="icon-button greyItem"
                                                style={{ width: '30px', marginTop: "-8px", background: "#f2f2f2", marginLeft: "20px" }}
                                                onClick={(e) => setSubAccountToDelete(submerchant.orgId)}>
                                                <IonIcon src=".\assets\icon\deletePresetBin.svg" />
                                            </IonButton>
                                        </IonCol>
                                    </IonItem>
                                );
                            })}
                        </IonList>
                    </IonGrid>
                </div>
                <RowWithDynamicGutter>
                    <IonButton
                        id="addSubMerchantButton"
                        color="secondary"
                        onClick={() => router.push("/tabs/newsubmerchant")}>Add New &#187;
                    </IonButton>
                </RowWithDynamicGutter>
            </PageAndContent>

            <IonModal className='smallModal' backdropDismiss={true} isOpen={!!subAccountToDelete}>
                <IonContent className="modelContent">
                    <IonText style={{ color: '#003466', textAlign: 'center' }}>
                        <b>
                            <div style={{ fontSize: '17px', marginTop: '8px' }}>
                                Are you sure you want to disable this subaccount?
                            </div>
                        </b>
                        <p style={{ fontWeight: '500' }}>This action cannot be undone</p>
                    </IonText>
                    <IonRow style={{ textAlign: 'center' }}>
                        <IonCol className="bottomColButton" onClick={() => setSubAccountToDelete(null)}>
                            <IonText style={{ fontSize: '17px', fontWeight: '400' }}>No</IonText>
                        </IonCol>
                        <IonCol className="bottomColButton" onClick={deleteSubAccount}>
                            {isDeleting
                                ? <IonSpinner style={{ marginLeft: '-15px', color: '#fff' }} />
                                : <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Yes</IonText>}
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
        : <PageSpinner></PageSpinner>}
    </>;
}
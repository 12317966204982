import { ApiPost, ApiGet, ApiPut, ApiDelete } from "../util/Api";
import { VatRate } from "./MerchantData";

export interface PaymentPresetBase {
    id: string;
    name: string;
}

export interface PaymentPreset extends PaymentPresetBase {
    created: Date;
    updated: Date;
    payload: PaymentPresetPayload;
    design: PaymentPresetDesign;
    shareUrl: string;
    vatRate?: VatRate;
}

export interface PaymentPresetPayload {
    amounts: number[];
    flags: PaymentPresetPayloadFlags;
}

export interface PaymentPresetPayloadFlags {
    allowCustomAmount: boolean;
    allowPayerName: boolean;
    allowDescription: boolean;
    allowGiftAid: boolean;
    showVatOnReceipt: boolean;
}

export interface PaymentPresetDesign {
    title: string;
    subTitle: string;
}

export interface PaymentPresetList {
    data: PaymentPreset[];
    continuation: string;
}

export const CreatePaymentPreset = async (name: string, payload: PaymentPresetPayload, design: PaymentPresetDesign, vatRate?: VatRate): Promise<PaymentPreset> => {
    var url = 'CreatePaymentPreset?';
    var response = await ApiPost(url, { name, payload, design });

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const UpdatePaymentPreset = async (paymentPreset: PaymentPreset): Promise<PaymentPreset> => {
    var url = 'UpdatePaymentPreset?';
    var response = await ApiPut(url, paymentPreset);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const ListPaymentPresets = async (
    continuation?: string,
    searchText?: string) => {

    var url = 'ListPaymentPresets?';
    if (searchText) url += `searchText=${searchText}&`;
    if (continuation) url += `continuation=${continuation}&`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const GetPaymentPreset = async (id: string): Promise<PaymentPreset> => {
    var url = `GetPaymentPreset?id=${id}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const DeletePaymentPreset = async (id: string): Promise<boolean> => {
    var url = `DeletePaymentPreset?id=${id}`;
    var response = await ApiDelete(url, { });
    return response.status === 200;
}